<template>
  <div>
    <apexchart
      width="100%"
      type="pie"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
export default {
  props: {
    work: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      series: [],
      chartOptions: {
        labels: [],
      },
    }
  },
  watch: {
    work() {
      this.series = this.value
      this.chartOptions = {
        labels: this.work,
      }
    },
  },
}
</script>
