<template>
  <b-card-code title="Work Summary Data">
    <b-row class="match-height mb-2">
      <b-col md="12">
        <b-form-group
          label="Date Range Filter"
          label-for="Role"
        >
          <date-range-picker
            ref="filter"
            v-model="filterDate"
            style="width:100%"
            :opens="'right'"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
          >
            <template
              v-slot:input="picker"
              style="min-width: 350px;"
            >
              {{ picker.startDate | date }} - {{ picker.endDate | date }}
            </template>
          </date-range-picker>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col md="5">
        <b-list-group
          v-for="item in SummaryData"
          :key="item.work"
        >
          <b-list-group-item>{{ item.work }}: <b>{{ item.value }}</b> ({{ (item.value / total * 100).toFixed(1) }}%) </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col md="7">
        <Chart
          :work="detailWork"
          :value="detailValue"
        />
      </b-col>
    </b-row>
  </b-card-code>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BRow,
  BCol,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'

import { required, email, numeric } from '@validations'
import axios from '@axios'
import dayjs from 'dayjs'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import store from '@/store/index'
import DateRangePicker from 'vue2-daterange-picker'
import Chart from './diagram.vue'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: {
    required,
    email,
    numeric,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCardCode,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    ToastificationContent,
    vSelect,
    Chart,
    DateRangePicker,
  },
  data() {
    return {
      SummaryData: '',
      total: 0,
      detailWork: [],
      detailValue: [],
      filterDate: {
        startDate: null,
        endDate: null,
      },
    }
  },
  watch: {
    filterDate() {
      this.getUserDetail()
    },
  },
  created() {
    if (store.state.userData.user.role === '1') {
      this.isAdmin = true
    } else {
      this.isAdmin = false
    }
  },
  mounted() {
    this.getUserDetail()
  },
  methods: {
    async getUserDetail() {
      const { data } = await axios.get('/summary/works', {
        params: {
          startDate:
            this.filterDate.startDate !== null
              ? dayjs(this.filterDate.startDate).format('YYYY-MM-DD 00:00:00')
              : undefined,
          endDate:
            this.filterDate.endDate !== null
              ? dayjs(this.filterDate.endDate).format('YYYY-MM-DD 23:59:59')
              : undefined,
        },
      })
      this.SummaryData = data
      this.detailWork = data.map(e => e.work)
      this.detailValue = data.map(e => e.value)
      this.total = this.detailValue.reduce((partialSum, a) => partialSum + a, 0)
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
.row {
  margin-bottom: 20px;
}
.form-group {
  margin-bottom: 5px;
}
.nopad {
  padding: 0;
}
.labelfull {
  .custom-checkbox {
    width: 100%;
  }
}
.labelfull .custom-checkbox {
  width: 100%;
}
.tanggapan2 {
  display: flex;
  align-items: center;
  justify-content: end;
  .avatar {
    width: 80px;
    margin-right: 20px;
    img {
      width: 100%;
    }
  }
  h2 {
    font-size: 16px;
  }
  h3 {
    font-size: 14px;
    font-weight: 400;
  }
  h4 {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .input {
    margin-left: 25px;
    flex-grow: 1;
  }
  .input-group-append {
    cursor: pointer;
  }
}

.photo {
  span {
    margin-bottom: 10px;
  }
  div {
    border-radius: 10px;
    max-width: 350px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
}

.link {
  margin-top: 10px;
  display: block;
  color: #23425e;
  cursor: pointer;
  font-weight: bold;
}
</style>
